<template>
  <template v-if="hadAdequateScreenSize || userStore.isVIP || isDev">
    <slot/>
  </template>

  <template v-else>
    <div class="mobile-disclaimer">
      <TeamfightLogo :size="4.8"/>

      <span>
          Unfortunately Teamfight.lol is currently only supporting our desktop version of the site.
          <br/><br/>
          We hope to have our mobile version supported soon! In the meantime, please switch to using Teamfight.lol on your laptop or desktop computer.
        </span>
    </div>
  </template>
</template>

<script lang="ts" setup>
import { useScreen } from "~/composables/useScreen";
import { useUserStore } from "~/stores/UserStore";
import { computed } from "vue";

const userStore = useUserStore();

const hadAdequateScreenSize = computed(() => import.meta.server || useScreen().width.value >= 960);

const config = useRuntimeConfig();
const isDev = computed(() => config.public.environment === 'development');

useSeoMeta({
  title: () => hadAdequateScreenSize ? undefined : 'Mobile Unavailable'
});
</script>

<style lang="scss" scoped>
.mobile-disclaimer {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 2rem;

  span {
    text-align: center;
    width: 60vw;
  }
}
</style>